import './carousel.styles.scss';

import PropTypes from 'prop-types';
import React from 'react';
import Slider from 'react-slick';
function CustomCarousel({
    children,
    classNameWrapper,
    responsive = defaultBreakPoints,
    slidesToShow,
    ...rest
}) {
    const slickCarouselSettings = {
        dots: false,
        infinite: false,
        speed: 800,
        slidesToShow: slidesToShow,
        slidesToScroll: slidesToShow,
        initialSlide: 0,
        responsive: responsive,
        ...rest
    };
    return (
        <Slider {...slickCarouselSettings} className={classNameWrapper}>
            {children}
        </Slider>
    );
}

CustomCarousel.propTypes = {
    classNameWrapper: PropTypes.string,
    children: PropTypes.any.isRequired,
    slidesToShow: PropTypes.number,
    responsive: PropTypes.any
};

export default CustomCarousel;

const defaultBreakPoints = [
    {
        breakpoint: 2600,
        settings: {
            slidesToShow: 4,
            slidesToScroll: 4
        }
    },
    {
        breakpoint: 1080,
        settings: {
            slidesToShow: 3,
            slidesToScroll: 3
        }
    },
    {
        breakpoint: 992,
        settings: {
            slidesToShow: 3.35,
            slidesToScroll: 3
        }
    },
    {
        breakpoint: 991,
        settings: {
            slidesToShow: 3.3,
            slidesToScroll: 3
        }
    },
    {
        breakpoint: 900,
        settings: {
            slidesToShow: 3,
            slidesToScroll: 3
        }
    },
    {
        breakpoint: 850,
        settings: {
            slidesToShow: 2.6,
            slidesToScroll: 2
        }
    },
    {
        breakpoint: 576,
        settings: {
            slidesToShow: 2.09,
            slidesToScroll: 2
        }
    },
    {
        breakpoint: 480,
        settings: {
            slidesToShow: 1.45,
            slidesToScroll: 1
        }
    },
    {
        breakpoint: 375,
        settings: {
            slidesToShow: 1.25,
            slidesToScroll: 1
        }
    }
];
