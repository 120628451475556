import { gql } from 'apollo-boost';

export const GET_TITLE = gql`
    query get_title($id: String!) {
        block: blockContentById(id: $id) {
            ... on BlockContentTitulo {
                title: fieldTitulo
                description: fieldSubtitulo
                banner: fieldImagenBanner {
                    entity {
                        ... on MediaImage {
                            image: fieldMediaImage {
                                derivative(style: EVENTOIMAGESTYLE) {
                                    url
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const GET_BANNER_FILE = gql`
    query get_pdf($id: String!) {
        block: blockContentById(id: $id) {
            ... on BlockContentTitulo {
                hasFile: fieldHasFile
                fileLink: fieldPdfDescargable {
                    entity {
                        ... on MediaDocument {
                            file: fieldMediaDocument {
                                entity {
                                    url
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
