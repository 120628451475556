// Styles
import './counter.styles.scss';

import React from 'react';

import IndependenceDate from '../../assets/images/act.svg';
import ConcordiaDate from '../../assets/images/pact.svg';
import CelebrationDate from '../../assets/images/torch.svg';
import Countdown from './children/countdown.component';

const Counter = () => {
    return (
        <div className="container counter-component shards-counter shards-counter--1 py-3 pb-4">
            <div className="container">
                <div className="row justify-content-center pt-2">
                    <div className="counter-item col-12 mb-sm-0 text-center headline-container">
                        <h2 className="text-white counter__title">
                            Llevá la cuenta para nuestras celebraciones patrias
                        </h2>
                    </div>
                </div>
                <div className="d-flex justify-content-center flex-wrap">
                    <div className="row d-flex flex-wrap justify-content-center dates-container">
                        <div className="col-sm-12 col-lg-4 counter-item">
                            <Countdown title="Celebración Patria" icon={CelebrationDate} />
                        </div>
                        <div className="col-sm-12 col-lg-4 counter-item">
                            <Countdown
                                title="Firma del Acta de Independencia"
                                icon={IndependenceDate}
                            />
                        </div>
                        <div className="col-sm-12 col-lg-4 counter-item">
                            <Countdown title="Firma del Pacto de Concordia" icon={ConcordiaDate} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Counter;
