import './simpleVideosCarousel.styles.scss';

import PropTypes from 'prop-types';
import React from 'react';

import ButtonLink from '../_shared/buttons/buttonsLink.component';
import { Text } from '../_shared/loadingMessage/loadingMessage.component';
import CustomCarousel from '../carousel/children/carousel/carousel.component';
import SimpleVideoCard from './children/simpleVideoCard/simpleVideoCard.component';

const SimpleVideosCarousel = ({ videos }) => {
    if (!videos) return <Text style={{ minHeight: '415px' }} />;

    const renderSlides = () => {
        return videos.map((video) => {
            return (
                <ButtonLink link={`/video/${video.id}`} key={video.id}>
                    <SimpleVideoCard
                        key={video.id}
                        src={video.cover.entity.file.fluidImg.url}
                        heading={video.title}
                    />
                </ButtonLink>
            );
        });
    };

    return (
        <CustomCarousel
            classNameWrapper="simple-video-carousel"
            slidesToShow={3}
            responsive={breakPoints}
            dots
            arrows={false}
        >
            {renderSlides()}
        </CustomCarousel>
    );
};

SimpleVideosCarousel.propTypes = {
    videos: PropTypes.array
};

export default React.memo(SimpleVideosCarousel);

export const breakPoints = [
    {
        breakpoint: 2600,
        settings: {
            slidesToShow: 3,
            slidesToScroll: 3
        }
    },
    {
        breakpoint: 1440,
        settings: {
            slidesToShow: 3,
            slidesToScroll: 3
        }
    },
    {
        breakpoint: 1300,
        settings: {
            slidesToShow: 2.6,
            slidesToScroll: 2.6
        }
    },
    {
        breakpoint: 1180,
        settings: {
            slidesToShow: 2.2,
            slidesToScroll: 2.2
        }
    },
    {
        breakpoint: 992,
        settings: {
            slidesToShow: 2.1,
            slidesToScroll: 2.1
        }
    },
    {
        breakpoint: 900,
        settings: {
            slidesToShow: 1.8,
            slidesToScroll: 1
        }
    },
    {
        breakpoint: 770,
        settings: {
            slidesToShow: 1.65,
            slidesToScroll: 1.65
        }
    },
    {
        breakpoint: 710,
        settings: {
            slidesToShow: 1.5,
            slidesToScroll: 1.5
        }
    },
    {
        breakpoint: 625,
        settings: {
            slidesToShow: 1.3,
            slidesToScroll: 1.3
        }
    },
    {
        breakpoint: 520,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1
        }
    },
    {
        breakpoint: 478,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1
        }
    },
    {
        breakpoint: 430,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1
        }
    },
    {
        breakpoint: 375,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1
        }
    },
    {
        breakpoint: 365,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1
        }
    }
];
