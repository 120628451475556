import './videoCarousel.styles.scss';

import React from 'react';

import { GET_TITLE } from '../../graphQL/queries/titleQuery';
import { GET_VIDEO_SLIDES } from '../../graphQL/queries/videoCarouselQueries';
import useFetch from '../../hooks/useFetch';
import ButtonLink from '../_shared/buttons/buttonsLink.component';
import CustomCarousel from '../carousel/children/carousel/carousel.component';
import SectionHeadline from '../sectionHeadline/sectionHeadline.component';
import Socials from '../socials/socials.component';
import FeaturedVideo from './children/featuredVideo.component';
import VideoCard from './children/videoCard.component';

const VideoCarousel = () => {
    const { data } = useFetch('video_carousel', GET_VIDEO_SLIDES);
    const { data: headerData } = useFetch('video_carousel__title', GET_TITLE, { id: '33' });

    const getVideoData = ({ cover, localVideo, remoteVideo, ...rest }) => {
        let url = remoteVideo?.entity?.url || localVideo?.entity?.file?.entity?.url;
        let image = cover?.entity?.file?.fluidImg?.url;
        let featuredImage = cover?.entity?.file?.featured?.url || image;
        return { url, cover: image, featuredImage, ...rest };
    };

    const renderSlides = () => {
        const videos = [...data.slides.list].slice(1);
        return videos.map((video) => (
            <VideoCard
                key={video.id}
                {...getVideoData(video)}
                redirectUrl={`/video/${video.id}`}
                noImageLink
            />
        ));
    };

    return (
        (headerData?.block?.title && data?.slides?.list?.length > 0 && (
            <div className="video-carousel container">
                <SectionHeadline>
                    <>
                        {headerData?.block?.title}
                        <p>{headerData?.block?.description}</p>
                    </>
                </SectionHeadline>
                <FeaturedVideo {...getVideoData([...data.slides.list].shift())} />
                <CustomCarousel
                    classNameWrapper="videos-carousel"
                    slidesToShow={4}
                    responsive={breakPoints}
                >
                    {renderSlides()}
                </CustomCarousel>
                <Socials />
                <div className="videos-page-link__container d-flex justify-content-center align-items-center">
                    <ButtonLink link="/videos" className="button-secondary">
                        Ver más
                    </ButtonLink>
                </div>
            </div>
        )) ||
        null
    );
};

const breakPoints = [
    {
        breakpoint: 2600,
        settings: {
            slidesToShow: 3.9,
            slidesToScroll: 3.9
        }
    },
    {
        breakpoint: 1440,
        settings: {
            slidesToShow: 3.9,
            slidesToScroll: 3.9
        }
    },
    {
        breakpoint: 1200,
        settings: {
            slidesToShow: 3.6,
            slidesToScroll: 3.6
        }
    },
    {
        breakpoint: 1180,
        settings: {
            slidesToShow: 3.2,
            slidesToScroll: 3.2
        }
    },
    {
        breakpoint: 992,
        settings: {
            slidesToShow: 3,
            slidesToScroll: 3
        }
    },
    {
        breakpoint: 900,
        settings: {
            slidesToShow: 2.5,
            slidesToScroll: 2
        }
    },
    {
        breakpoint: 760,
        settings: {
            slidesToShow: 2.25,
            slidesToScroll: 2
        }
    },
    {
        breakpoint: 710,
        settings: {
            slidesToShow: 2.1,
            slidesToScroll: 1.8
        }
    },
    {
        breakpoint: 625,
        settings: {
            slidesToShow: 1.8,
            slidesToScroll: 1.8
        }
    },
    {
        breakpoint: 520,
        settings: {
            slidesToShow: 1.6,
            slidesToScroll: 1
        }
    },
    {
        breakpoint: 478,
        settings: {
            slidesToShow: 1.4,
            slidesToScroll: 1
        }
    },
    {
        breakpoint: 430,
        settings: {
            slidesToShow: 1.3,
            slidesToScroll: 1
        }
    },
    {
        breakpoint: 375,
        settings: {
            slidesToShow: 1.15,
            slidesToScroll: 1
        }
    },
    {
        breakpoint: 365,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1
        }
    }
];

export default VideoCarousel;
