// Types
import CalendarTypes from '../calendar/calendar.type';

const INITIAL_STATE = {
    detailOpen: false
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CalendarTypes.OPEN_CALENDAR_DETAIL: {
            return {
                ...state,
                detailOpen: !state.detailOpen,
                currentEvent: action.payload
            };
        }
        default: {
            return state;
        }
    }
};

export { reducer as calendarReducer };
