import './videoCard.styles.scss';

import PropTypes from 'prop-types';
import React, { useState } from 'react';

import ButtonLink from '../../_shared/buttons/buttonsLink.component';
import PlayButton from '../../_shared/playButton/playButton.component';
import Portal from '../../_shared/portal/portal.component';
import VideoLightBox from '../../_shared/videoLightbox/videoLightbox.component';
import LazyImage from '../../lazyImage/lazyImage.component';

const VideoCard = ({ cover, url, ...rest }) => {
    const { title, description } = rest;
    const classes = !(title && description) ? ' no-content' : '';
    const [isPlaying, setIsPlaying] = useState(false);

    const handleClick = () => {
        setIsPlaying(true && url);
    };

    return (
        <div
            className={`video-card d-flex justify-content-start align-items-center flex-column${classes}`}
        >
            <WithLink url={!rest.noImageLink ? rest.redirectUrl : null}>
                <div className="d-flex justify-content-center align-items-center">
                    <PlayButton callback={handleClick} classes="keep-sm" />
                    <LazyImage src={cover} classNameWrapper={`cover-img${classes}`} />
                </div>
            </WithLink>
            {(title || description) && (
                <div className="video-card-body">
                    {title && (
                        <WithLink url={rest.redirectUrl}>
                            <h3>{title}</h3>
                        </WithLink>
                    )}
                    {description?.summary && <p>{description.summary}</p>}
                </div>
            )}
            {isPlaying && (
                <Portal id="root">
                    <VideoLightBox url={url} handleClose={() => setIsPlaying(false)} />
                </Portal>
            )}
        </div>
    );
};

const WithLink = ({ url, children }) => {
    if (!url) return children;
    return <ButtonLink link={url}>{children}</ButtonLink>;
};

WithLink.propTypes = {
    url: PropTypes.string,
    children: PropTypes.any
};

VideoCard.propTypes = {
    cover: PropTypes.string,
    description: PropTypes.object,
    title: PropTypes.string,
    url: PropTypes.string
};

export default React.memo(VideoCard);
