import './footer.styles.scss';
import '../socials/socials.styles.scss';

import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';

import Facebook from '../../assets/images/facebook.svg';
import Instagram from '../../assets/images/instagram.svg';
import Twitter from '../../assets/images/twitter.svg';
import ButtonAction from '../_shared/buttons/buttonsAction.component';
import LazyImage from '../lazyImage/lazyImage.component';

const Footer = ({ social }) => {
    return (
        <nav className="footer-main">
            <div className="container">
                <div className="row justify-content-between">
                    <div className="col-12 col-lg-5">
                        <div className="row mb-md-4 mb-lg-0 footer-main__nav">
                            <div className="col-4">
                                <strong className="text-white d-block mb-2">Ayuda</strong>
                                <NavLink
                                    className="text-white d-block"
                                    to="/faqs"
                                    activeClassName="footer-main__link--active"
                                >
                                    <small>FAQs</small>
                                </NavLink>
                                <NavLink
                                    className="text-white d-block"
                                    to="/ayuda"
                                    activeClassName="footer-main__link--active"
                                >
                                    <small>Contactanos</small>
                                </NavLink>
                            </div>
                            <div className="col-4">
                                <strong className="text-white d-block mb-2">Participá</strong>
                                <NavLink
                                    className="text-white d-block"
                                    to="/participa"
                                    activeClassName="footer-main__link--active"
                                >
                                    <small>Inscribí tu iniciativa</small>
                                </NavLink>
                            </div>
                            <div className="col-4">
                                <strong className="text-white d-block mb-2">Acerca de</strong>
                                <NavLink
                                    className="text-white d-block"
                                    to="/acerca-de"
                                    activeClassName="footer-main__link--active"
                                >
                                    <small>Más Información</small>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4">
                        <div className="row">
                            <div className="col-12">
                                <strong className="text-white d-inline-block mb-2">
                                    Subscribite
                                </strong>
                                <p className="text-white footer-main__actions-text">
                                    Enterate de las novedades de 200 años Costa Rica desde tu correo
                                    electrónico.
                                </p>
                                <div id="mc_embed_signup">
                                    <form
                                        action="https://alarezmedia.us1.list-manage.com/subscribe/post?u=b25d823bd4b4e3a578ca4d510&amp;id=d65dd30118"
                                        method="post"
                                        id="mc-embedded-subscribe-form"
                                        name="mc-embedded-subscribe-form"
                                        className="validate footer-main__form"
                                        target="_blank"
                                        noValidate
                                    >
                                        <div
                                            id="mc_embed_signup_scroll"
                                            className="d-flex justify-content-start"
                                        >
                                            <input
                                                className="email input--suscribe"
                                                id="mce-EMAIL"
                                                name="EMAIL"
                                                placeholder="Correo Electrónico"
                                                required
                                                type="email"
                                            />
                                            <div
                                                style={{ position: 'absolute', left: '-5000px' }}
                                                aria-hidden="true"
                                            >
                                                <input
                                                    name="b_b25d823bd4b4e3a578ca4d510_d65dd30118"
                                                    tabIndex="-1"
                                                    type="text"
                                                />
                                            </div>
                                            <ButtonAction
                                                className="m-0 button-suscribe no-shadows"
                                                id="button-suscribe"
                                                name="subscribe"
                                                type="submit"
                                            >
                                                Subscribite
                                            </ButtonAction>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row align-items-center footer-main__copyright">
                    <div className="col-12 col-lg-2 text-center text-lg-left">
                        <LazyImage
                            src={social.settings[0].logoFooter.entity.image.url}
                            alt={social.settings[0].logoFooter.entity.image.alt}
                        />
                    </div>
                    <div className="col-12 col-lg-6 text-center text-lg-left mt-md-4 mt-lg-0">
                        <span className="text-white d-block">
                            <span className="Copyright">
                                © Copyright {new Date().getFullYear()} - 200 Años Costa Rica
                            </span>
                            <NavLink
                                className="text-white"
                                to="/terminos"
                                activeClassName="footer-main__link--active"
                            >
                                <small className="terms">Términos y Condiciones</small>
                            </NavLink>
                        </span>
                    </div>
                    <div className="col-12 col-lg-4">
                        {social.settings.map((item, index) => (
                            <ul className="social_icons" key={`social${index}`}>
                                {item?.facebook?.url?.path && (
                                    <li>
                                        <a
                                            className="d-flex"
                                            href={item.facebook.url.path}
                                            rel="noreferrer"
                                            target="_blank"
                                        >
                                            <LazyImage src={Facebook} alt="Facebook" />
                                            <span className="text-white">Facebook</span>
                                        </a>
                                    </li>
                                )}
                                {item?.instagram?.url?.path && (
                                    <li>
                                        <a
                                            className="d-flex"
                                            href={item.instagram.url.path}
                                            rel="noreferrer"
                                            target="_blank"
                                        >
                                            <LazyImage src={Instagram} alt="Instagram" />
                                            <span className="text-white">Instagram</span>
                                        </a>
                                    </li>
                                )}
                                {item?.twitter?.url?.path && (
                                    <li>
                                        <a
                                            className="d-flex"
                                            href={item.twitter.url.path}
                                            rel="noreferrer"
                                            target="_blank"
                                        >
                                            <LazyImage src={Twitter} alt="Instagram" />
                                            <span className="text-white">Twitter</span>
                                        </a>
                                    </li>
                                )}
                            </ul>
                        ))}
                    </div>
                </div>
            </div>
        </nav>
    );
};

Footer.propTypes = {
    social: PropTypes.object
};

export default Footer;
