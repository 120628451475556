import './simpleVideoCard.styles.scss';

import PropTypes from 'prop-types';
import React from 'react';

import LazyImage from '../../../lazyImage/lazyImage.component';

const SimpleVideoCard = ({ src, heading }) => {
    return (
        <div className="simple-video-card">
            <div className="d-flex justify-content-center align-items-center card-image">
                <LazyImage src={src} alt={heading} className="cover-img" />
            </div>
            <div className="card-content">
                <p>{heading}</p>
            </div>
        </div>
    );
};

SimpleVideoCard.propTypes = {
    src: PropTypes.string.isRequired,
    heading: PropTypes.string.isRequired
};

export default SimpleVideoCard;
