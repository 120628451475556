import './videoLightbox.styles.scss';

import PropTypes from 'prop-types';
import React from 'react';
import ReactPlayer from 'react-player';

import ButtonAction from '../buttons/buttonsAction.component';

const VideoLightBox = ({ url, handleClose }) => {
    const settings = {
        controls: true,
        loop: false,
        playing: true,
        config: {
            youtube: {
                onUnstarted: () => 2,
                embedOptions: {
                    rel: 0
                }
            },
            file: {
                forceVideo: true
            }
        }
    };

    return (
        <div className="video-lightbox d-flex justify-content-center align-items-center">
            <div className="container">
                <ButtonAction
                    className="button-circular--close button--close"
                    onClick={handleClose}
                >
                    <svg
                        width="15"
                        height="15"
                        viewBox="0 0 15 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M14.4372 1.95991L13.0398 0.5625L7.49972 6.10259L1.95963 0.5625L0.562222 1.95991L6.10231 7.5L0.562222 13.0401L1.95963 14.4375L7.49972 8.89741L13.0398 14.4375L14.4372 13.0401L8.89713 7.5L14.4372 1.95991Z"
                            fill="#2D4481"
                        />
                    </svg>
                </ButtonAction>
                <ReactPlayer
                    className="react-player"
                    height="100%"
                    width="100%"
                    url={url}
                    {...settings}
                />
            </div>
        </div>
    );
};

VideoLightBox.propTypes = {
    url: PropTypes.any,
    handleClose: PropTypes.func
};

export default VideoLightBox;
