import { GET_CATEGORIES } from '../graphQL/queries/videoQueries';
import useFetch from './useFetch';

const useVideoCategories = () => {
    const { data, isLoading, error } = useFetch('video_categories', GET_CATEGORIES);

    if (isLoading || error) return { isLoading, error, data: [] };

    let dictionary = {};
    let categories = [];

    (data?.results?.list || []).forEach((item) => {
        const id = item.category.entity.id;

        if (!(id in dictionary)) {
            dictionary[id] = item;
            categories.push(item.category.entity);
        }
    });

    return { data: [...categories], isLoading, error };
};

export default useVideoCategories;
