import './horizontalVideoCard.styles.scss';

import PropTypes from 'prop-types';
import React from 'react';

import LazyImage from '../../lazyImage/lazyImage.component';
import ButtonLink from '../buttons/buttonsLink.component';
import PlayButton from '../playButton/playButton.component';

const HorizontalVideoCard = ({ url, image, title }) => {
    return (
        <ButtonLink link={url} className="horizontal-video-card d-flex">
            <div className="horizontal-video-card__image d-flex justify-content-center align-items-center">
                <LazyImage src={image} alt={title} width={150} height={145} />
                <PlayButton classes="keep-sm" />
            </div>
            <div className="horizontal-video-card__title">
                <h4 className="title">{title}</h4>
            </div>
        </ButtonLink>
    );
};

HorizontalVideoCard.propTypes = {
    url: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
};

export default HorizontalVideoCard;
