import { useApolloClient } from '@apollo/react-hooks';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';

import { GET_EVENTS } from '../graphQL/queries/eventsQuery';
import { GET_EVENTS_COUNT } from '../graphQL/queries/eventsQuery';

const useGetAllEvents = (isCalendar = false) => {
    const [loading, setLoading] = useState(false);
    const [allEvents, setAllEvents] = useState(undefined);
    const [count, setCount] = useState(0);
    const client = useApolloClient();

    const runQuery = useCallback(async () => {
        setLoading(true);

        const eventsCount = await client.query({
            query: GET_EVENTS_COUNT,
            variables: {
                currDate: !isCalendar ? moment().unix() : ''
            }
        });

        setCount(eventsCount?.data?.events?.count);

        const allEvents = await client.query({
            query: GET_EVENTS,
            variables: {
                limit: eventsCount?.data?.events?.count,
                offset: 0,
                currDate: !isCalendar ? moment().unix() : ''
            }
        });

        setLoading(false);
        setAllEvents(allEvents?.data?.events?.items);
    }, [isCalendar, client]);

    useEffect(() => {
        if (!allEvents?.length) {
            runQuery();
        }
    }, [runQuery, allEvents]);

    return {
        loading,
        data: allEvents,
        count
    };
};

export default useGetAllEvents;
