import { useQuery } from 'react-query';

import { graphQLClient } from '../graphQL/client';

const useFetch = (id, query, variables = {}, initialState) => {
    return useQuery(
        id,
        async () => {
            const data = await graphQLClient.request(query, variables);
            return data;
        },
        { keepPreviousData: true, ...initialState }
    );
};

export default useFetch;
