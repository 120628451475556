// Styles
import './buttons.styles.scss';

import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

const ButtonLink = ({ target, link, className, ...props }) => {
    return (
        <Link className={className} to={link} target={target} {...props}>
            {props.children}
        </Link>
    );
};

ButtonLink.propTypes = {
    link: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.any,
    target: PropTypes.string
};

export default ButtonLink;
