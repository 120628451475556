import './videoDetail.styles.scss';

import moment from 'moment';
import React from 'react';
import { Helmet } from 'react-helmet';
import ReactPlayer from 'react-player';
import { useHistory, useParams } from 'react-router-dom';

import HorizontalVideoCard from '../../../components/_shared/horizontalVideoCard/horizontalVideoCard.component';
import { Text } from '../../../components/_shared/loadingMessage/loadingMessage.component';
import ShareLinks from '../../../components/_shared/social-media-share-links/shareLinks.component';
import { GET_RELATED_VIDEOS, GET_VIDEO_DETAIL } from '../../../graphQL/queries/videoQueries';
import useFetch from '../../../hooks/useFetch';

const VideoDetail = () => {
    const { id } = useParams();
    const history = useHistory();
    const { data, isLoading, error } = useFetch(['post', id], GET_VIDEO_DETAIL, {
        id
    });

    const { data: sidebarData } = useFetch(
        ['posts-' + data?.video?.category?.id, id],
        GET_RELATED_VIDEOS,
        {
            category: data?.video?.category?.id ?? '',
            id
        }
    );

    if (isLoading) {
        return <Text isPage />;
    }

    if (error || (!data?.video && !isLoading)) {
        history.push('/404');
        return null;
    }

    const title = `${data.video?.title} | Bicentenario de la Independencia de Costa Rica`;

    return (
        <React.Fragment>
            <Helmet>
                <title>{title}</title>
                <meta property="og:type" content="article" />
                <meta name="description" content={data.video.description.summary} />
                <meta property="og:title" content={title} />
                <meta property="og:image" content={data.video.cover.entity.media.fluidImg.url} />
                <meta property="og:description" content={data.video.description.summary} />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={data.video.description.summary} />
                <meta name="twitter:image" content={data.video.cover.entity.media.fluidImg.url} />
            </Helmet>
            <div className="video">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="video__player">
                                <ReactPlayer
                                    className="react-player"
                                    height="100%"
                                    width="100%"
                                    url={
                                        data.video?.remoteVideo?.entity?.url ||
                                        data.video?.localVideo?.entity?.file?.entity?.url
                                    }
                                    {...settings}
                                />
                            </div>
                            <h1 className="video__title">{data.video.title}</h1>

                            <p className="video__date">
                                {moment.unix(data.video.created).format('MMM DD, YYYY')}
                            </p>
                            <ShareLinks title={data.video.title} />
                            <div
                                className="video__content"
                                dangerouslySetInnerHTML={{
                                    __html: data.video.description.processed
                                }}
                            ></div>
                        </div>
                        <div className="col-lg-4 videos-sidebar">
                            {sidebarData?.related?.videos?.length > 0 &&
                                sidebarData?.related?.videos?.map((video) => (
                                    <HorizontalVideoCard
                                        url={`/video/${video.id}`}
                                        image={video.cover.entity.media.fluidImg.url}
                                        title={video.title}
                                        key={video.id}
                                    />
                                ))}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

const settings = {
    controls: true,
    loop: false,
    playing: false,
    config: {
        youtube: {
            onUnstarted: () => 2,
            embedOptions: {
                rel: 0
            }
        },
        file: {
            forceVideo: true
        }
    }
};

export default VideoDetail;
