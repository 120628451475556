// Global Styles
import './assets/scss/theme/_global.scss';
import './assets/scss/theme/_typo.scss';
import 'aos/dist/aos.css';

import { ApolloProvider } from '@apollo/react-hooks';
import AOS from 'aos';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

// Layout
import InternalLayout from './layouts/internal/internal.component';
// Pages
import Home from './pages/home';
const MapPage = React.lazy(() => import('./pages/agenda/mapPage'));
const Participa = React.lazy(() => import('./pages/take-part'));
const ParticipaForm = React.lazy(() => import('./pages/take-part-Form'));
const Pillar = React.lazy(() => import('./pages/pillar'));
const Press = React.lazy(() => import('./pages/press/press'));
const Page404 = React.lazy(() => import('./pages/404/404'));
const About = React.lazy(() => import('./pages/about'));
const CalendarPage = React.lazy(() => import('./pages/agenda/calendarPage'));
const CategoryLanding = React.lazy(() => import('./pages/categoriaLanding/categoryLanding'));
const ConvoDetails = React.lazy(() => import('./pages/convo'));
const Event = React.lazy(() => import('./pages/event'));
const FAQs = React.lazy(() => import('./pages/faqs'));
const Help = React.lazy(() => import('./pages/help'));
const Timeline = React.lazy(() => import('./pages/timeline'));
const Historians = React.lazy(() => import('./pages/historians'));
const ProgrammePage = React.lazy(() => import('./pages/agenda/programmePage'));
const ReleaseDetail = React.lazy(() => import('./pages/releaseDetail/releaseDetail'));
const TermsAndConditions = React.lazy(() => import('./pages/termsAndConditions'));
import FacebookProvider from 'react-facebook/dist/FacebookProvider';
import { QueryClient, QueryClientProvider } from 'react-query';

import LoadingMessage from './components/_shared/loadingMessage/loadingMessage.component';
import { EventsContextProvider } from './context/eventsContext';
import VideoDetail from './pages/videos/videoDetail/videoDetail';
import Videos from './pages/videos/videos';
import { store } from './redux/store';
import { client } from './Utils/apolloClient';

const queryClient = new QueryClient();

const RouteWrapper = ({ children, Layout, title, description, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) =>
                Layout ? (
                    <Layout title={title} description={description} {...props}>
                        {children}
                    </Layout>
                ) : (
                    <>{children}</>
                )
            }
        />
    );
};

RouteWrapper.propTypes = {
    children: PropTypes.any,
    Layout: PropTypes.any,
    title: PropTypes.string,
    description: PropTypes.string
};

function App() {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    return (
        <Provider store={store}>
            <ApolloProvider client={client}>
                <main>
                    <FacebookProvider appId="557053305232281">
                        <EventsContextProvider>
                            <QueryClientProvider client={queryClient}>
                                <LoadingMessage isPage>
                                    <Switch>
                                        <RouteWrapper
                                            path="/"
                                            title="Bicentenario de la Independencia de Costa Rica"
                                            description="Sitio oficial del Bicentenario de la Independencia de Costa Rica"
                                            exact
                                        >
                                            <Home />
                                        </RouteWrapper>
                                        <RouteWrapper
                                            path="/videos"
                                            title="Videos"
                                            description="Una mirada al Bicentenario"
                                            Layout={InternalLayout}
                                            exact
                                        >
                                            <Videos />
                                        </RouteWrapper>
                                        <RouteWrapper
                                            path="/video/:id"
                                            title="Video"
                                            description="Una mirada al Bicentenario"
                                            Layout={InternalLayout}
                                        >
                                            <VideoDetail />
                                        </RouteWrapper>
                                        <RouteWrapper
                                            path="/pilar/:id"
                                            title="Pilares"
                                            description="Pilares del Bicentenario"
                                            Layout={InternalLayout}
                                        >
                                            <Pillar />
                                        </RouteWrapper>
                                        <RouteWrapper
                                            path="/hilo-narrativo/:id"
                                            title="Hilo Narrativo"
                                            description="CAMINOS DE NUESTRA CONMEMORACIÓN"
                                            Layout={InternalLayout}
                                        >
                                            <Pillar />
                                        </RouteWrapper>
                                        <RouteWrapper
                                            path="/acerca-de"
                                            title="Acerca de"
                                            description="Acerca de"
                                            Layout={InternalLayout}
                                        >
                                            <About />
                                        </RouteWrapper>
                                        <RouteWrapper
                                            path="/participa"
                                            title="Participá"
                                            description="Participá"
                                            Layout={InternalLayout}
                                        >
                                            <Participa />
                                        </RouteWrapper>
                                        <RouteWrapper
                                            path="/agenda/mapa"
                                            title="Mapa de Eventos"
                                            description="Mapa de los eventos de la agenda."
                                            Layout={InternalLayout}
                                        >
                                            <MapPage />
                                        </RouteWrapper>
                                        <RouteWrapper
                                            path="/agenda/calendario"
                                            title="Calendario de Eventos"
                                            description="Calendario de los eventos de la agenda."
                                            Layout={InternalLayout}
                                        >
                                            <CalendarPage />
                                        </RouteWrapper>
                                        <RouteWrapper
                                            path={[
                                                '/agenda',
                                                '/agenda/programacion',
                                                '/agenda/mapa',
                                                '/agenda/calendario'
                                            ]}
                                            title="Agenda"
                                            description="Agenda del Bicentenario"
                                            Layout={InternalLayout}
                                        >
                                            <ProgrammePage />
                                        </RouteWrapper>
                                        <RouteWrapper
                                            path="/pilar/:id"
                                            title="Pilares"
                                            description="Pilares del Bicentenario"
                                            Layout={InternalLayout}
                                        >
                                            <Pillar />
                                        </RouteWrapper>
                                        <RouteWrapper
                                            path="/hilo-narrativo/:id"
                                            title="Hilo Narrativo"
                                            description="CAMINOS DE NUESTRA CONMEMORACIÓN"
                                            Layout={InternalLayout}
                                        >
                                            <Pillar />
                                        </RouteWrapper>
                                        <RouteWrapper
                                            path="/prensa"
                                            title="Prensa"
                                            description="Prensa del Bicentenario"
                                            Layout={InternalLayout}
                                        >
                                            <Press />
                                        </RouteWrapper>
                                        <RouteWrapper
                                            path="/seccion-de-prensa"
                                            title="Sección de Prensa"
                                            description="Sección de Prensa"
                                            Layout={InternalLayout}
                                        >
                                            <CategoryLanding />
                                        </RouteWrapper>
                                        <RouteWrapper
                                            path="/comunicado-detalle/:id"
                                            title="Detalles de comunicado"
                                            description="Detalles de comunicado"
                                            Layout={InternalLayout}
                                        >
                                            <ReleaseDetail />
                                        </RouteWrapper>
                                        <RouteWrapper
                                            path="/participa-formulario"
                                            title="Participá formulario"
                                            description="Participá formulario"
                                            Layout={InternalLayout}
                                        >
                                            <ParticipaForm />
                                        </RouteWrapper>
                                        <RouteWrapper
                                            path="/convocatoria/:id"
                                            title="Convocatoria"
                                            description="Convocatoria"
                                            Layout={InternalLayout}
                                        >
                                            <ConvoDetails />
                                        </RouteWrapper>
                                        <RouteWrapper
                                            path="/evento/:id"
                                            title="Evento"
                                            Layout={InternalLayout}
                                        >
                                            <Event />
                                        </RouteWrapper>
                                        <RouteWrapper
                                            path="/faqs/:category?"
                                            title="Preguntas frecuentes"
                                            Layout={InternalLayout}
                                        >
                                            <FAQs />
                                        </RouteWrapper>
                                        <RouteWrapper
                                            path="/ayuda"
                                            title="Ayuda"
                                            description="Ayuda"
                                            Layout={InternalLayout}
                                        >
                                            <Help />
                                        </RouteWrapper>
                                        <RouteWrapper
                                            path="/nuestra-historia"
                                            title="Nuestra historia"
                                            Layout={InternalLayout}
                                        >
                                            <Timeline />
                                        </RouteWrapper>
                                        <RouteWrapper
                                            path="/historiadores"
                                            title="Historiadores"
                                            Layout={InternalLayout}
                                        >
                                            <Historians />
                                        </RouteWrapper>
                                        <RouteWrapper
                                            path="/terminos"
                                            title="Términos y Condiciones"
                                            Layout={InternalLayout}
                                        >
                                            <TermsAndConditions />
                                        </RouteWrapper>
                                        <RouteWrapper
                                            path="*"
                                            title="404"
                                            description="Página no encontrada."
                                            Layout={InternalLayout}
                                        >
                                            <Page404 />
                                        </RouteWrapper>
                                    </Switch>
                                </LoadingMessage>
                            </QueryClientProvider>
                        </EventsContextProvider>
                    </FacebookProvider>
                </main>
            </ApolloProvider>
        </Provider>
    );
}

export default React.memo(App);
