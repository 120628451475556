import { encode as base64_encode } from 'base-64';
import { GraphQLClient } from 'graphql-request';

const token = base64_encode(`${process.env.REACT_APP_USERNAME}:${process.env.REACT_APP_PASSWORD}`);

export const graphQLClient = new GraphQLClient(process.env.REACT_APP_GRAPHQL_SERVER, {
    headers: {
        authorization: `Basic ${token}`
    }
});
