import './menu.styles.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import { NavLink } from 'react-router-dom';

import { GET_SUBMENUS } from '../../graphQL/queries/submenus';
import useFetch from '../../hooks/useFetch';
import useWindowSize from '../../hooks/useWindowSize';
import LazyImage from '../lazyImage/lazyImage.component';
import MenuLink from './children/link/menuLink.component';

function Menu({ srcBanner, srcHeroBanner, defaultMenu }) {
    const { data } = useFetch('about_submenu', GET_SUBMENUS);
    const { width: currentWidth } = useWindowSize();
    const logoConfigs = useMemo(
        () => ({
            mobile: { x: 133, y: 75, isMobile: true },
            desktop: { x: 200, y: 118, isMobile: false }
        }),
        []
    );
    const [expanded, setExpanded] = useState(false);
    const [logoSize, setLogoSize] = useState(logoConfigs.mobile);

    const getSubMenu = (linkUrl) => {
        return data?.results?.data.find((item) => item.parent === linkUrl);
    };

    useEffect(() => {
        const handleResize = () => {
            if (currentWidth < 992 && !logoSize.isMobile) {
                setLogoSize(logoConfigs.mobile);
            }
            if (logoSize.isMobile && currentWidth >= 992) {
                setLogoSize(logoConfigs.desktop);
            }
        };

        if (
            (logoSize.isMobile && currentWidth >= 992) ||
            (currentWidth < 992 && !logoSize.isMobile)
        ) {
            handleResize();
        }
    }, [currentWidth, logoConfigs, logoSize.isMobile]);

    return (
        <div className="main-menu-wrapp">
            <Navbar expanded={expanded} className="main-menu" collapseOnSelect expand="lg">
                <Navbar.Brand>
                    <NavLink to="/">
                        <LazyImage
                            className="branding-color"
                            src={srcHeroBanner}
                            alt="main logo"
                            height={logoSize.y}
                            useImg={true}
                            width={logoSize.x}
                        />
                        <LazyImage
                            className="branding-flat"
                            src={srcBanner}
                            alt="main logo"
                            height={logoSize.y}
                            useImg={true}
                            width={logoSize.x}
                        />
                    </NavLink>
                </Navbar.Brand>
                <Navbar.Toggle
                    onClick={() => setExpanded(expanded ? false : 'expanded')}
                    aria-controls="responsive-navbar-nav"
                />
                <div className="main-menu__items">
                    {defaultMenu.map((item, index) => (
                        <MenuLink
                            key={index}
                            handleClick={setExpanded.bind(this, false)}
                            item={item}
                            submenu={getSubMenu(item.link)}
                        />
                    ))}
                </div>
            </Navbar>
        </div>
    );
}

Menu.propTypes = {
    srcBanner: PropTypes.string,
    srcHeroBanner: PropTypes.string,
    defaultMenu: PropTypes.array
};

Menu.defaultProps = {
    defaultMenu: [
        {
            text: 'Programación',
            link: '/agenda'
        },
        {
            text: 'Nuestra Historia',
            link: '/nuestra-historia'
        },
        {
            text: 'Multimedia',
            link: '/videos'
        },
        {
            text: 'Escribamos historia',
            externalLink: true,
            link: process.env.REACT_APP_NUESTRA_HISTORIA
        },
        {
            text: 'Prensa',
            link: '/prensa'
        },
        {
            text: 'Acerca de',
            link: '/acerca-de'
        }
    ]
};

export default React.memo(Menu);
