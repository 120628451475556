/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import PropTypes from 'prop-types';
import React from 'react';
import { useState } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

import useWindowSize from '../../../../hooks/useWindowSize';
import SubMenu from '../submenu/submenu.component';

const MenuLink = ({ item, submenu, handleClick }) => {
    const { width } = useWindowSize();
    const [isOpen, setIsOpen] = useState(width && width > 992);

    const handleDropdownClick = () => {
        if (width < 992) {
            setIsOpen((prev) => !prev);
        }
    };

    return (
        <Navbar.Collapse id="responsive-navbar-nav">
            {submenu?.links && (isOpen || width >= 992) && <SubMenu links={submenu.links} />}
            <Nav>
                {!item.externalLink ? (
                    <React.Fragment>
                        <NavLink
                            onClick={handleClick}
                            to={item.link}
                            activeClassName="main-menu__item-active"
                        >
                            {item.text}
                        </NavLink>
                        {submenu?.links && (
                            <span
                                aria-label="Hide submenu"
                                onClick={handleDropdownClick}
                                className={`collapse-dropdown-btn${isOpen ? ' open' : ''}`}
                                tabIndex={-1}
                            >
                                <ArrowIcon />
                            </span>
                        )}
                    </React.Fragment>
                ) : (
                    <a href={item.link}>{item.text}</a>
                )}
            </Nav>
        </Navbar.Collapse>
    );
};

MenuLink.propTypes = {
    item: PropTypes.object.isRequired,
    submenu: PropTypes.any,
    handleClick: PropTypes.func.isRequired
};

export default React.memo(MenuLink);

const ArrowIcon = () => (
    <svg width="10" height="15" viewBox="0 0 10 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M0.617235 2.31873L5.81448 7.52732L0.617236 12.7359L2.21726 14.3359L9.02588 7.52732L2.21726 0.718705L0.617235 2.31873Z"
            fill="#fff"
        />
    </svg>
);
