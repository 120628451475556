import { gql } from 'apollo-boost';

export const GET_VIDEO_SLIDES = gql`
    query GET_VIDEO_SLIDES {
        slides: nodeQuery(
            filter: {
                conditions: [{ field: "type", value: ["video"] }, { field: "status", value: "1" }]
            }
        ) {
            list: entities {
                ... on NodeVideo {
                    id: nid
                    title
                    description: body {
                        summary
                    }
                    cover: fieldImagen {
                        entity {
                            ... on MediaImage {
                                file: fieldMediaImage {
                                    fluidImg: derivative(style: MAX650X650) {
                                        url
                                    }
                                    featured: derivative(style: MAX2600X2600) {
                                        url
                                    }
                                    alt
                                }
                            }
                        }
                    }
                    remoteVideo: fieldVideo {
                        entity {
                            ... on MediaRemoteVideo {
                                url: fieldMediaOembedVideo
                            }
                        }
                    }
                    localVideo: fieldVideo {
                        entity {
                            ... on MediaVideo {
                                file: fieldMediaVideoFile {
                                    entity {
                                        url
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
