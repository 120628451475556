import './videos.styles.scss';

import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';

import LoadingMessage, {
    Text
} from '../../components/_shared/loadingMessage/loadingMessage.component';
import TopTabs from '../../components/eventsList/childs/topTabs/top.tabs.component';
import SimpleVideosCarousel from '../../components/simpleVideoCarousel/simpleVideosCarousel.component';
import VideoGrid from '../../components/videoGrid/videoGrid.component';
import { GET_VIDEO_SLIDES } from '../../graphQL/queries/videoCarouselQueries';
import useFetch from '../../hooks/useFetch';
import useVideoCategories from '../../hooks/useVideoCategories';

const LIMIT_PER_PAGE = 8;

const Videos = ({ description }) => {
    const [filters, setFilters] = React.useState('all');
    const [filteredCategories, setFilteredCategories] = React.useState([]);

    const { data, error } = useFetch('video_carousel', GET_VIDEO_SLIDES);
    const categories = useVideoCategories();

    const handleFilter = React.useCallback(
        (id) => {
            if (id !== filters && id) {
                setFilters(id);
                setFilteredCategories(
                    id == 'all'
                        ? categories.data
                        : categories.data.filter((category) => category.id === id)
                );
            }
        },
        [categories.data, filters]
    );

    if (error) {
        return (
            <div
                style={{ height: '50vh' }}
                className="d-flex align-items-center justify-content-center"
            >
                <p className="text-center">Sin videos que mostrar</p>
            </div>
        );
    }

    return (
        <Fragment>
            <Helmet>
                <meta name="description" content={description} />
                <meta property="og:description" content={description} />
                <meta name="twitter:description" content={description} />
            </Helmet>
            <section className="videos-heading-section">
                <div className="row justify-content-center">
                    <h1 className="videos__title title-section">Una mirada al Bicentenario</h1>
                </div>

                <div className="row carousel-container">
                    <div className="col-sm-12 w-100">
                        <SimpleVideosCarousel videos={data?.slides?.list} />
                    </div>
                </div>
            </section>
            <div className="video-grid-container">
                <div className="container">
                    <div className="row w-100">
                        <div className="col-12 p-0">
                            {categories.isLoading ? (
                                <LoadingMessage />
                            ) : (
                                <TopTabs
                                    categories={categories.data}
                                    action={handleFilter}
                                    currentFilter={filters}
                                    noCollapse
                                />
                            )}
                        </div>
                    </div>
                </div>
                {categories.isLoading ? (
                    <Text style={{ height: '50vh' }} />
                ) : (
                    (filteredCategories.length > 0 ? filteredCategories : categories.data).map(
                        (category) => {
                            const isPreview = !filteredCategories.length > 0 || filters === 'all';

                            return (
                                <VideoGrid
                                    key={category.id}
                                    gridTitle={category.name}
                                    categoryId={category.id}
                                    limit={isPreview ? LIMIT_PER_PAGE : LIMIT_PER_PAGE * 2}
                                    handleClick={isPreview ? handleFilter : null}
                                    isPreview={isPreview}
                                />
                            );
                        }
                    )
                )}
            </div>
        </Fragment>
    );
};

Videos.propTypes = {
    description: PropTypes.string
};

Videos.defaultProps = {
    description: 'Una mirada al Bicentenario'
};

export default React.memo(Videos);
