import { gql } from 'graphql-request';

export const GET_SUBMENUS = gql`
    query getSubMenus {
        results: blockContentQuery(
            filter: {
                conditions: [{ field: "type", value: "submenu" }, { field: "status", value: "1" }]
            }
        ) {
            data: entities {
                ... on BlockContentSubmenu {
                    parent: fieldParentLink
                    links: fieldEnlace {
                        ... on FieldBlockContentSubmenuFieldEnlace {
                            title
                            url: uri
                        }
                    }
                }
            }
        }
    }
`;
