import PropTypes from 'prop-types';
import React from 'react';

const SectionHeadline = ({ children, ...props }) => {
    return (
        <h2 {...props} className={`title-section ${props.className || ''}`}>
            {children}
        </h2>
    );
};

SectionHeadline.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string
};

export default SectionHeadline;
