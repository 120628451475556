import PropTypes from 'prop-types';
import React from 'react';

import LazyImage from '../../lazyImage/lazyImage.component';
function Countdown({ title, icon }) {
    return (
        <>
            <div className="text-center">
                <h2 className="counter-item__title">{title}</h2>
            </div>
            <div className="counter-body d-flex justify-content-center">
                <LazyImage src={icon} alt={title} isLocal useImg />
            </div>
        </>
    );
}
Countdown.propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.any
};
export default Countdown;
