import { gql } from 'graphql-request';

export const GET_HOME = gql`
    query getHome($heroImageStyle: ImageStyleId!) {
        hero: nodeQuery(
            filter: {
                conditions: [
                    { field: "type", value: ["hero_banner"] }
                    { field: "status", value: "1", operator: EQUAL }
                ]
            }
        ) {
            items: entities {
                ... on NodeHeroBanner {
                    title
                    subtitle: fieldSubtitulo
                    link: fieldCtaUrl {
                        url {
                            path
                        }
                        title
                    }
                    background: fieldFotoBackground {
                        entity {
                            ... on MediaImage {
                                image: fieldMediaImage {
                                    responsiveImg: derivative(style: $heroImageStyle) {
                                        url
                                    }
                                    url
                                    alt
                                    width
                                    height
                                }
                            }
                        }
                    }
                }
            }
        }
        pillars: nodeQuery(
            filter: {
                conditions: [
                    { field: "type", value: ["pilar"] }
                    { field: "status", value: "1", operator: EQUAL }
                    { field: "field_pilar_o_hilo_", value: ["Pilar"] }
                ]
            }
        ) {
            items: entities {
                ... on NodePilar {
                    id: nid
                    title
                    summary: fieldResumen
                    icon: fieldIcono {
                        entity {
                            ... on MediaImage {
                                image: fieldMediaImage {
                                    responsiveImg: derivative(style: THUMBNAIL) {
                                        url
                                    }
                                    url
                                }
                            }
                        }
                    }
                    content: fieldComponentes {
                        entity {
                            ... on ParagraphTextHtml {
                                body: fieldBody {
                                    html: processed
                                }
                            }
                        }
                    }
                    pillarImage: fieldFotoPrincipalPilar {
                        entity {
                            ... on MediaImage {
                                image: fieldMediaImage {
                                    responsiveImg: derivative(style: MAX650X650) {
                                        url
                                    }
                                    url
                                    alt
                                    width
                                    height
                                }
                            }
                        }
                    }
                }
            }
        }
        events: nodeQuery(
            filter: {
                conditions: [
                    { field: "type", value: ["event_"] }
                    { field: "status", value: "1", operator: EQUAL }
                ]
            }
            limit: 300
        ) {
            items: entities {
                ... on NodeEvent {
                    id: nid
                    name: title
                    date: fieldFechaDeInicio
                    rangeDates: fieldDateRange {
                        startDate
                        endDate
                    }
                    location: fieldProvincia {
                        province
                        canton
                    }
                    description: fieldDescripcion {
                        value: processed
                    }
                    type: fieldTipoDeEvento {
                        entity {
                            value: entityLabel
                        }
                    }
                    imageEvent: fieldFotoPrincipal {
                        entity {
                            ... on MediaImage {
                                image: fieldMediaImage {
                                    responsiveImg: derivative(style: _649PXX362PX) {
                                        url
                                    }
                                    url
                                    height
                                    width
                                    alt
                                }
                            }
                        }
                    }
                    category: fieldCategoriaDeEvento {
                        entity {
                            name: entityLabel
                            id: entityId
                        }
                    }
                }
            }
        }
        eventCategories: taxonomyTermQuery(
            filter: { conditions: [{ field: "vid", value: ["categoria_de_eventos"] }] }
            limit: 50
        ) {
            categories: entities {
                ... on TaxonomyTermCategoriaDeEventos {
                    name
                    id: tid
                }
            }
        }
        narratives: nodeQuery(
            filter: {
                conditions: [
                    { field: "type", value: ["Pilar"] }
                    { field: "status", value: "1", operator: EQUAL }
                    { field: "field_pilar_o_hilo_", value: ["Hilo"] }
                ]
            }
        ) {
            items: entities {
                ... on NodePilar {
                    id: nid
                    title
                    summary: fieldResumen
                    icon: fieldIcono {
                        entity {
                            ... on MediaImage {
                                image: fieldMediaImage {
                                    responsiveImg: derivative(style: MEDIUM) {
                                        url
                                    }
                                    url
                                }
                            }
                        }
                    }
                    content: fieldComponentes {
                        entity {
                            ... on ParagraphTextHtml {
                                body: fieldBody {
                                    html: processed
                                }
                            }
                        }
                    }
                    pillarImage: fieldFotoPrincipalPilar {
                        entity {
                            ... on MediaImage {
                                image: fieldMediaImage {
                                    responsiveImg: derivative(style: MAX650X650) {
                                        url
                                        height
                                    }
                                    url
                                    alt
                                    width
                                    height
                                }
                            }
                        }
                    }
                }
            }
        }
        concepts: blockContentById(id: "3") {
            ... on BlockContentCardsDeConceptos {
                items: fieldConcepto {
                    item: entity {
                        ... on ParagraphCardsDeConceptos {
                            title: fieldTituloConcepto
                            description: fieldDescripcionCards {
                                value
                            }
                            hoverImage: fieldHover {
                                entity {
                                    ... on MediaImage {
                                        url: fieldMediaImage {
                                            responsiveImg: derivative(style: THUMBNAIL) {
                                                url
                                            }
                                            value: url
                                        }
                                    }
                                }
                            }
                            firstHoverTitle: fieldTituloHoverLinea1
                            secondHoverTitle: fieldTituloHoverLinea2
                        }
                    }
                }
            }
        }
        social: nodeQuery(
            filter: {
                conditions: [
                    { field: "type", value: ["links_redes_sociales"] }
                    { field: "status", value: "1", operator: EQUAL }
                ]
            }
            limit: 1
        ) {
            settings: entities {
                ... on NodeLinksRedesSociales {
                    facebook: fieldFacebook {
                        url {
                            path
                        }
                    }
                    twitter: fieldTwitter {
                        url {
                            path
                        }
                    }
                    instagram: fieldInstagram {
                        url {
                            path
                        }
                    }
                    tiktok: fieldTiktok {
                        url {
                            path
                        }
                    }
                    logoNavbar: fieldLogoNavbar {
                        entity {
                            ... on MediaImage {
                                image: fieldMediaImage {
                                    url
                                    alt
                                    responsiveImg: derivative(style: LARGE) {
                                        url
                                    }
                                }
                            }
                        }
                    }

                    logoHeroBanner: fieldLogoHeroBanner {
                        entity {
                            ... on MediaImage {
                                image: fieldMediaImage {
                                    responsiveImg: derivative(style: THUMBNAIL) {
                                        url
                                    }
                                    url
                                    alt
                                }
                            }
                        }
                    }

                    logoFooter: fieldLogoNavbar {
                        entity {
                            ... on MediaImage {
                                image: fieldMediaImage {
                                    url
                                    alt
                                    responsiveImg: derivative(style: MEDIUM) {
                                        url
                                    }
                                }
                            }
                        }
                    }

                    logo: fieldLogoNavbar {
                        entity {
                            ... on MediaImage {
                                image: fieldMediaImage {
                                    responsiveImg: derivative(style: LARGE) {
                                        url
                                    }
                                    url
                                    alt
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
