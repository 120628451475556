import { gql } from 'graphql-request';

export const GET_GRID_VIDEOS = gql`
    query getVideos($id: String!, $limit: Int, $offset: Int) {
        results: nodeQuery(
            filter: {
                conditions: [
                    { field: "type", value: ["video"] }
                    { field: "status", value: "1" }
                    { field: "field_categoria.entity.tid", value: [$id], operator: EQUAL }
                ]
            }
            sort: { field: "changed", direction: DESC }
            limit: $limit
            offset: $offset
        ) {
            total: count
            videos: entities {
                ... on NodeVideo {
                    id: nid
                    title
                    created
                    description: body {
                        summary
                        processed
                    }
                    fieldCategoria {
                        entity {
                            ... on TaxonomyTermCategoriaDeEventos {
                                id: tid
                                name
                            }
                        }
                    }
                    cover: fieldImagen {
                        entity {
                            ... on MediaImage {
                                media: fieldMediaImage {
                                    fluidImg: derivative(style: MAX650X650) {
                                        url
                                    }
                                }
                            }
                        }
                    }
                    localVideo: fieldVideo {
                        entity {
                            ... on MediaVideo {
                                fieldMediaVideoFile {
                                    entity {
                                        url
                                    }
                                }
                            }
                        }
                    }
                    remoteVideo: fieldVideo {
                        entity {
                            ... on MediaRemoteVideo {
                                url: fieldMediaOembedVideo
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const GET_CATEGORIES = gql`
    query getCategories {
        results: nodeQuery(
            filter: {
                conditions: [{ field: "type", value: ["video"] }, { field: "status", value: "1" }]
            }
            sort: { field: "changed", direction: DESC }
            limit: 100
        ) {
            list: entities {
                ... on NodeVideo {
                    category: fieldCategoria {
                        entity {
                            ... on TaxonomyTermCategoriaDeEventos {
                                id: tid
                                name
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const GET_VIDEO_DETAIL = gql`
    query getVideo($id: String!) {
        video: nodeById(id: $id) {
            ... on NodeVideo {
                id: nid
                title
                created
                description: body {
                    summary
                    processed
                }
                category: fieldCategoria {
                    id: targetId
                    entity {
                        ... on TaxonomyTermCategoriaDeEventos {
                            id: tid
                            name
                        }
                    }
                }
                cover: fieldImagen {
                    entity {
                        ... on MediaImage {
                            media: fieldMediaImage {
                                fluidImg: derivative(style: MAX650X650) {
                                    url
                                }
                            }
                        }
                    }
                }
                localVideo: fieldVideo {
                    entity {
                        ... on MediaVideo {
                            file: fieldMediaVideoFile {
                                entity {
                                    url
                                }
                            }
                        }
                    }
                }
                remoteVideo: fieldVideo {
                    entity {
                        ... on MediaRemoteVideo {
                            url: fieldMediaOembedVideo
                        }
                    }
                }
            }
        }
    }
`;

export const GET_RELATED_VIDEOS = gql`
    query getRelatedVideos($category: String!, $id: String!) {
        related: nodeQuery(
            filter: {
                conditions: [
                    { field: "status", value: "1" }
                    { field: "type", value: "video" }
                    { field: "field_categoria.target_id", value: [$category] }
                    { field: "nid", value: [$id], operator: NOT_IN }
                ]
            }
            limit: 5
        ) {
            videos: entities {
                ... on NodeVideo {
                    id: nid
                    title
                    cover: fieldImagen {
                        entity {
                            ... on MediaImage {
                                media: fieldMediaImage {
                                    fluidImg: derivative(style: MAX650X650) {
                                        url
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
