import PropTypes from 'prop-types';
import React, { useState } from 'react';

import PlayButton from '../../_shared/playButton/playButton.component';
import Portal from '../../_shared/portal/portal.component';
import VideoLightBox from '../../_shared/videoLightbox/videoLightbox.component';
import LazyImage from '../../lazyImage/lazyImage.component';

const FeaturedVideo = ({ featuredImage, url, ...rest }) => {
    const { title, description } = rest;
    const [isPlaying, setIsPlaying] = useState(false);

    return (
        <div className="video-card featured-video-card d-flex justify-content-center align-items-center flex-column">
            <PlayButton callback={() => setIsPlaying(true)} classes="keep-sm" />
            <LazyImage src={featuredImage} classNameWrapper="cover-img h-100 w-100" />

            {(title || description) && (
                <div className="video-card-body">
                    {title && <h3>{title}</h3>}
                    {description?.summary && <p>{description.summary}</p>}
                </div>
            )}
            {isPlaying && (
                <Portal id="root">
                    <VideoLightBox url={url} handleClose={() => setIsPlaying(false)} />
                </Portal>
            )}
        </div>
    );
};

FeaturedVideo.propTypes = {
    featuredImage: PropTypes.string,
    url: PropTypes.string
};

export default FeaturedVideo;
