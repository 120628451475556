import './paginator.styles.scss';

import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Pagination } from 'react-bootstrap';

import { calculateVisiblePages, getItemClass, getTotalPages } from '../../Utils/paginationUtils';

const Paginator = ({ itemsLength, itemsPerPage, page, paginate }) => {
    const PAGE_NUM = useMemo(
        () => getTotalPages(itemsLength, itemsPerPage),
        [itemsLength, itemsPerPage]
    );

    const pagination = useMemo(() => {
        let items = [];
        const { startPage, endPage } = calculateVisiblePages(PAGE_NUM, page);

        for (let i = startPage; i <= endPage; i++) {
            const itemClass = getItemClass(i, PAGE_NUM);
            const item = (
                <Pagination.Item
                    key={i}
                    active={i == page}
                    className={`no-shadows ${itemClass}`}
                    onClick={paginate.bind(this, i)}
                >
                    {i}
                </Pagination.Item>
            );

            items.push(item);
        }

        return items;
    }, [PAGE_NUM, page, paginate]);

    return (
        pagination?.length && (
            <Pagination className="d-flex justify-content-center">
                <Pagination.Prev
                    className={`mr-1 no-shadows prev-page-button ${
                        page == 1 ? 'd-none' : 'd-block'
                    }`}
                    onClick={paginate.bind(this, page - 1)}
                />
                {page ? pagination : null}
                <Pagination.Next
                    className={`ml-1 no-shadows next-page-button ${
                        page == PAGE_NUM ? 'd-none' : 'd-block'
                    }`}
                    onClick={paginate.bind(this, page + 1)}
                />
            </Pagination>
        )
    );
};

Paginator.propTypes = {
    itemsLength: PropTypes.number.isRequired,
    itemsPerPage: PropTypes.number,
    page: PropTypes.number.isRequired,
    paginate: PropTypes.func.isRequired
};

Paginator.defaultProps = {
    itemsPerPage: 10
};

export default React.memo(Paginator);
