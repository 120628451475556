import './hero-slider.styles.scss';

import PropTypes from 'prop-types';
import React from 'react';
import Carousel from 'react-bootstrap/Carousel';

import ButtonLink from '../_shared/buttons/buttonsLink.component';
import Counter from '../counter/counter.component';
import LazyImage from '../lazyImage/lazyImage.component';
import Menu from '../menu/menu.component';

function HeroSlider({ items, social }) {
    return (
        <div className="hero-slider">
            <div className="hero-slider__slide">
                <Menu
                    srcBanner={social.settings[0].logoNavbar.entity.image.url}
                    srcHeroBanner={social.settings[0].logoHeroBanner.entity.image.url}
                />
                <Carousel keyboard={true} defaultActiveIndex={0} interval={50000} controls={false}>
                    {items.map((item, index) => (
                        <Carousel.Item key={`carousel-item-${index}`}>
                            <LazyImage
                                className="hero-slider__img"
                                src={item.background.entity.image.responsiveImg.url}
                                alt={item.background.entity.image.alt}
                                height={window.innerWidth > 1024 ? 900 : 800}
                                useImg={true}
                            />
                            <Carousel.Caption>
                                <div className="container">
                                    {index <= 0 ? (
                                        <h1 className="display-2 mb-3 text-white text-shadow--large-2 hero-slider__title">
                                            {item.title}
                                        </h1>
                                    ) : (
                                        <h2 className="display-2 mb-3 text-white text-shadow--large-2 hero-slider__title">
                                            {item.title}
                                        </h2>
                                    )}

                                    <h3 className="p-0 text-white hero-slider__sub">
                                        {item.subtitle}
                                    </h3>
                                    <div className="d-flex">
                                        {/^https?:\/\//.test(item.link.url.path) ? (
                                            <a
                                                className="button-quaternary"
                                                href={item.link.url.path}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                {item.link.title}
                                            </a>
                                        ) : (
                                            <ButtonLink
                                                className="button-quaternary"
                                                link={item.link.url.path}
                                            >
                                                {item.link.title}
                                            </ButtonLink>
                                        )}
                                    </div>
                                </div>
                            </Carousel.Caption>
                        </Carousel.Item>
                    ))}
                </Carousel>
            </div>
            <Counter />
        </div>
    );
}

HeroSlider.propTypes = {
    items: PropTypes.array,
    social: PropTypes.object
};

export default React.memo(HeroSlider);
