import './child/shareLinks.styles.scss';

import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { Overlay, Tooltip } from 'react-bootstrap';
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton
} from 'react-share';

import copyIcon from '../../../assets/images/icons/social_copylink.svg';
import facebookIcon from '../../../assets/images/icons/social_fb.svg';
import linkedinIcon from '../../../assets/images/icons/social_linkedin.svg';
import twitterIcon from '../../../assets/images/icons/social_twitter.svg';
import whatsappIcon from '../../../assets/images/icons/social_whatsapp.svg';
import LazyImage from '../../lazyImage/lazyImage.component';
const ShareLinks = ({ title, facebookLink, linkedInLink, twitterLink, toCopyLink, socialList }) => {
    const socialLink = window.location;
    const [showTooltip, setShowTooltip] = useState(false);
    const target = useRef(null);

    const isIncluded = (socialName) => {
        if (socialList) {
            return socialList.includes(socialName);
        }
        return true;
    };
    const copyToClipboard = () => {
        var textField = document.createElement('textarea');
        textField.innerText = toCopyLink || socialLink;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
        setShowTooltip(true);
        setTimeout(() => setShowTooltip(false), 600);
    };
    return (
        <div className="social-media d-flex justify-content-start align-items-center flex-wrap">
            {isIncluded('facebook') && (
                <div className="social-media-link">
                    <FacebookShareButton
                        url={facebookLink || socialLink}
                        title={title}
                        className="default-social-button"
                    >
                        <div className="logo-container">
                            <LazyImage src={facebookIcon} alt="facebook-logo" />
                        </div>
                    </FacebookShareButton>
                </div>
            )}
            {isIncluded('whatsapp') && (
                <div className="social-media-link">
                    <WhatsappShareButton
                        url={socialLink}
                        title={title}
                        className="default-social-button"
                    >
                        <div className="logo-container">
                            <LazyImage src={whatsappIcon} alt="whatsapp-logo" />
                        </div>
                    </WhatsappShareButton>
                </div>
            )}
            {isIncluded('copyLink') && (
                <div className="social-media-link">
                    <button type="button" onClick={copyToClipboard} ref={target}>
                        <div className="logo-container">
                            <LazyImage src={copyIcon} alt="copy-logo" />
                        </div>
                    </button>
                    <Overlay target={target.current} show={showTooltip} placement="bottom">
                        {(props) => (
                            <Tooltip id="overlay-example" {...props}>
                                ¡Copiado!
                            </Tooltip>
                        )}
                    </Overlay>
                </div>
            )}

            {isIncluded('twitter') && (
                <div className="social-media-link">
                    <TwitterShareButton
                        url={twitterLink || socialLink}
                        title={title}
                        className="default-social-button"
                    >
                        <div className="logo-container">
                            <LazyImage src={twitterIcon} alt="twitter-logo" />
                        </div>
                    </TwitterShareButton>
                </div>
            )}
            {isIncluded('linkedin') && (
                <div className="social-media-link">
                    <LinkedinShareButton
                        url={linkedInLink || socialLink}
                        title={title}
                        className="default-social-button"
                    >
                        <div className="logo-container">
                            <LazyImage src={linkedinIcon} alt="linkedin-logo" />
                        </div>
                    </LinkedinShareButton>
                </div>
            )}
        </div>
    );
};
ShareLinks.propTypes = {
    facebookLink: PropTypes.string,
    linkedInLink: PropTypes.string,
    title: PropTypes.string,
    toCopyLink: PropTypes.string,
    twitterLink: PropTypes.string,
    socialList: PropTypes.array
};
export default ShareLinks;
