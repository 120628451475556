export const getTotalPages = (total, itemsPerPage) => Math.ceil(total / itemsPerPage);

export const calculateVisiblePages = (total, currentPage) => {
    let startPage = 1;
    let endPage = total;

    if (total > 9) {
        if (currentPage > 5) {
            startPage = currentPage - 4;
        }
        if (currentPage < total - 4) {
            endPage = currentPage + 4;
        }
    }

    return { startPage, endPage };
};

export const getItemClass = (pageNum, total) => {
    let isFirst = pageNum == 1 && 'first-page-button';
    let isLast = pageNum == total && 'last-page-button';

    return isFirst || isLast || '';
};
