import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { encode as base64_encode } from 'base-64';

const httpLink = createHttpLink({
    uri: process.env.REACT_APP_GRAPHQL_SERVER
});

const authLink = setContext((_, { headers }) => {
    const token = base64_encode(
        `${process.env.REACT_APP_USERNAME}:${process.env.REACT_APP_PASSWORD}`
    );

    return {
        headers: {
            ...headers,
            authorization: `Basic ${token}`
        }
    };
});

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
});

export { client };
