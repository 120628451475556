import { gql } from 'apollo-boost';

export const GET_EVENTS = gql`
    query getEvents($limit: Int, $offset: Int) {
        events: nodeQuery(
            filter: {
                conditions: [
                    { field: "type", value: ["event_"] }
                    { field: "status", value: "1", operator: EQUAL }
                ]
            }
            limit: $limit
            offset: $offset
            sort: { field: "field_date_range", direction: ASC }
        ) {
            items: entities {
                ... on NodeEvent {
                    id: nid
                    name: title
                    startDate: fieldFechaDeInicio
                    date: fieldFechaDeInicio
                    endDate: fieldFechaFinalizacion
                    rangeDates: fieldDateRange {
                        startDate
                        endDate
                    }
                    locationModality: fieldSede
                    institution: fieldInstitucionOrganizadora {
                        entity {
                            name: entityLabel
                            id: entityId
                            ... on NodeInstitucion {
                                institutionLink: fieldEnlaceInstitucion {
                                    url {
                                        path
                                    }
                                }
                            }
                        }
                    }
                    description: fieldDescripcion {
                        value: processed
                    }
                    type: fieldTipoDeEvento {
                        entity {
                            value: entityLabel
                        }
                    }
                    location: fieldProvincia {
                        province
                        canton
                        district
                    }
                    geolocation: fieldGeolocalizacion {
                        lat
                        lng
                    }
                    type: fieldTipoDeEvento {
                        entity {
                            value: entityLabel
                        }
                    }
                    mainImage: fieldFotoPrincipalBackup {
                        responsiveImg: derivative(style: _861PXX546PX) {
                            url
                            height
                        }
                        alt
                    }
                    imageEvent: fieldFotoPrincipal {
                        entity {
                            ... on MediaImage {
                                image: fieldMediaImage {
                                    responsiveImg: derivative(style: _861PXX546PX) {
                                        url
                                        height
                                    }
                                    alt
                                }
                            }
                        }
                    }
                    category: fieldCategoriaDeEvento {
                        entity {
                            name: entityLabel
                            id: entityId
                        }
                    }
                    pillars: fieldPillarAndJustification {
                        entity {
                            ... on ParagraphPillarAndJustification {
                                fieldPilar {
                                    entity {
                                        nid
                                        title
                                    }
                                }
                            }
                        }
                    }
                    hilo: fieldHilo {
                        entity {
                            name: entityLabel
                            id: entityId
                        }
                    }
                }
            }
            count
        }
    }
`;

export const GET_EVENTS_FILTERED = gql`
    query getEvents($limit: Int, $offset: Int, $filter: EntityQueryFilterInput) {
        events: nodeQuery(
            filter: $filter
            limit: $limit
            offset: $offset
            sort: { field: "field_date_range", direction: ASC }
        ) {
            items: entities {
                ... on NodeEvent {
                    id: nid
                    name: title
                    rangeDates: fieldDateRange {
                        startDate
                        endDate
                    }
                    locationModality: fieldSede
                    institution: fieldInstitucionOrganizadora {
                        entity {
                            name: entityLabel
                            id: entityId
                            ... on NodeInstitucion {
                                institutionLink: fieldEnlaceInstitucion {
                                    url {
                                        path
                                    }
                                }
                            }
                        }
                    }
                    description: fieldDescripcion {
                        value: processed
                    }
                    type: fieldTipoDeEvento {
                        entity {
                            value: entityLabel
                        }
                    }
                    location: fieldProvincia {
                        province
                        canton
                        district
                    }
                    geolocation: fieldGeolocalizacion {
                        lat
                        lng
                    }
                    type: fieldTipoDeEvento {
                        entity {
                            value: entityLabel
                        }
                    }
                    mainImage: fieldFotoPrincipalBackup {
                        responsiveImg: derivative(style: _861PXX546PX) {
                            url
                            height
                        }
                        alt
                    }
                    imageEvent: fieldFotoPrincipal {
                        entity {
                            ... on MediaImage {
                                image: fieldMediaImage {
                                    responsiveImg: derivative(style: _861PXX546PX) {
                                        url
                                        height
                                    }
                                    alt
                                }
                            }
                        }
                    }
                    category: fieldCategoriaDeEvento {
                        entity {
                            name: entityLabel
                            id: entityId
                        }
                    }
                    pillars: fieldPillarAndJustification {
                        entity {
                            ... on ParagraphPillarAndJustification {
                                fieldPilar {
                                    entity {
                                        nid
                                        title
                                    }
                                }
                            }
                        }
                    }
                    hilo: fieldHilo {
                        entity {
                            name: entityLabel
                            id: entityId
                        }
                    }
                }
            }
            count
        }
    }
`;

export const GET_FILTERS = gql`
    query getFilters {
        institutions: nodeQuery(
            filter: {
                conditions: [
                    { field: "type", value: ["institucion"] }
                    { field: "status", value: "1", operator: EQUAL }
                ]
            }
            sort: { field: "title", direction: ASC }
            limit: 1000
        ) {
            entities {
                ... on NodeInstitucion {
                    id: nid
                    title
                }
            }
        }
        pillars: nodeQuery(
            filter: {
                conditions: [
                    { field: "type", value: ["pilar"] }
                    { field: "status", value: "1", operator: EQUAL }
                    { field: "field_pilar_o_hilo_", value: "Pilar", operator: EQUAL }
                ]
            }
            sort: { field: "title", direction: ASC }
            limit: 1000
        ) {
            entities {
                ... on NodePilar {
                    id: nid
                    title
                }
            }
        }
        categories: taxonomyTermQuery(
            filter: { conditions: [{ field: "vid", value: ["categoria_de_eventos"] }] }
            sort: { field: "name", direction: ASC }
            limit: 1000
        ) {
            entities {
                ... on TaxonomyTermCategoriaDeEventos {
                    id: tid
                    title: name
                }
            }
        }
    }
`;

export const GET_EVENTS_COUNT = gql`
    query getEvents {
        events: nodeQuery(
            filter: {
                conditions: [
                    { field: "type", value: ["event_"] }
                    { field: "status", value: "1", operator: EQUAL }
                ]
            }
        ) {
            count
        }
    }
`;
