// Styles
import './buttons.styles.scss';

import PropTypes from 'prop-types';
import React from 'react';

const ButtonAction = ({ className, ...props }) => {
    return (
        <button type="button" className={className} {...props}>
            {props.children}
        </button>
    );
};

ButtonAction.propTypes = {
    className: PropTypes.string,
    children: PropTypes.any
};

export default React.memo(ButtonAction);
