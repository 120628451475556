import './socials.styles.scss';

import React from 'react';

import Facebook from '../../assets/images/facebook-blue.svg';
import Instagram from '../../assets/images/instagram-blue.svg';
import Twitter from '../../assets/images/twitter-blue.svg';
import { GET_SOCIAL } from '../../graphQL/queries/configQuery';
import useFetch from '../../hooks/useFetch';
import LazyImage from '../lazyImage/lazyImage.component';

const Socials = () => {
    const { isLoading, error, data } = useFetch('social-configs', GET_SOCIAL);

    return (
        !isLoading &&
        !error && (
            <div>
                {data.social.settings.length > 0 &&
                    data.social.settings.map((item, index) => (
                        <ul className="social_icons" key={`social${index}`}>
                            {item?.facebook?.url?.path && (
                                <li>
                                    <a
                                        className="d-flex"
                                        href={item?.facebook?.url?.path}
                                        rel="noreferrer"
                                        target="_blank"
                                    >
                                        <LazyImage
                                            src={Facebook}
                                            alt={item?.facebook?.title || 'facebook'}
                                        />
                                        <span>Facebook</span>
                                    </a>
                                </li>
                            )}
                            {item?.instagram?.url?.path && (
                                <li>
                                    <a
                                        className="d-flex"
                                        href={item?.instagram?.url?.path}
                                        rel="noreferrer"
                                        target="_blank"
                                    >
                                        <LazyImage
                                            src={Instagram}
                                            alt={item?.instagram?.title || 'instagram'}
                                        />
                                        <span>Instagram</span>
                                    </a>
                                </li>
                            )}
                            {item?.twitter?.url?.path && (
                                <li>
                                    <a
                                        className="d-flex"
                                        href={item?.twitter?.url?.path}
                                        rel="noreferrer"
                                        target="_blank"
                                    >
                                        <LazyImage
                                            src={Twitter}
                                            alt={item?.twitter?.title || 'twitter'}
                                        />
                                        <span>Twitter</span>
                                    </a>
                                </li>
                            )}
                        </ul>
                    ))}
            </div>
        )
    );
};

export default Socials;
