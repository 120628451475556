import './loadingMessage.styles.scss';

import PropTypes from 'prop-types';
import React, { Suspense } from 'react';

export const Text = ({ isPage, ...rest }) => (
    <p className="loading-message" style={{ height: `100${isPage ? 'vh' : '%'}` }} {...rest}>
        Cargando...
    </p>
);

const LoadingMessage = (props) => {
    return <Suspense fallback={<Text isPage={props.isPage} />}>{props.children}</Suspense>;
};
Text.propTypes = {
    isPage: PropTypes.bool
};
LoadingMessage.propTypes = {
    children: PropTypes.any,
    isPage: PropTypes.bool
};
export default LoadingMessage;
