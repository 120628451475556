import React from 'react';
import Helmet from 'react-helmet';

import LoadingMessage, {
    Text
} from '../components/_shared/loadingMessage/loadingMessage.component';
import HeroSlider from '../components/heroSlider/heroSlider.component';
import StickNetwork from '../components/stickNetwork/stick-network';
import VideoCarousel from '../components/videocarousel/videoCarousel.component';
import { GET_HOME } from '../graphQL/queries/homeQueries';
import useFetch from '../hooks/useFetch';

const Footer = React.lazy(() => import('../components/footer/footer.component'));
const CarouselCards = React.lazy(() => import('../components/carousel/carousel.cards.component'));
// eslint-disable-next-line no-unused-vars
const CarouselLogos = React.lazy(() => import('../components/carousel/carousel.logos.component'));
const ConceptsSection = React.lazy(() => import('../components/concepts/concepts.component'));
const Cta = React.lazy(() => import('../components/cta/cta.component'));
const Features = React.lazy(() => import('../components/features/features.component'));
const Pillars = React.lazy(() => import('../components/pillars/pillars.component'));
const EventsList = React.lazy(() => import('../components/eventsList/events.list.component'));

function Home() {
    const isMobile = window.innerWidth <= 768;
    const { data, isLoading, error } = useFetch('home', GET_HOME, {
        heroImageStyle: isMobile ? 'MAX1300X1300' : '_1440PXX966PX'
    });

    const pageTitle = `Bicentenario de la Independecia de Costa Rica`;
    const pageDescription = `Sitio oficial del Bicentenario de la Independecia de Costa Rica`;
    const location = window.location.href;

    if (error) {
        return <p>Error</p>;
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{pageTitle}</title>
                <meta name="description" content={pageDescription} />
                <meta
                    property="og:image"
                    content={data?.hero?.items?.[0].background.entity.image.url}
                />
                <meta property="og:title" content={pageTitle} />
                <meta property="og:description" content={pageDescription} />
                <meta name="twitter:title" content={pageTitle} />
                <meta name="twitter:description" content={pageDescription} />
                <meta
                    name="twitter:image"
                    content={data?.hero?.items?.[0].background.entity.image.url}
                />
                <meta property="og:type" content="website" />
                <link rel="canonical" href={location} />
                <meta property="og:url" content={location} />
            </Helmet>
            {!data || isLoading ? (
                <Text style={{ height: '100vh' }} />
            ) : (
                <div>
                    <HeroSlider social={data.social} items={data.hero.items} />
                    <VideoCarousel />
                    <LoadingMessage isPage={true}>
                        <ConceptsSection conceptList={data.concepts.items} />
                        <Pillars cards={data.pillars.items} />
                        <CarouselCards cardsData={data.narratives.items} />
                        {data?.events?.items && (
                            <EventsList
                                showTabs={true}
                                categories={data.eventCategories.categories}
                                items={data.events.items}
                            />
                        )}
                        <Cta />
                        <Features />
                        <CarouselLogos />
                        <Footer social={data.social} />
                    </LoadingMessage>
                    <StickNetwork />
                </div>
            )}
        </>
    );
}

export default Home;
