import './lazyImage.styles.scss';

import PropTypes from 'prop-types';
import React from 'react';
import LazyLoad from 'react-lazyload';

import placeholder from '../../assets/images/placeholder.svg';
import { checkWebpSupport } from '../../Utils/imagesUtils';

const handleError = (e) => (e.target.src = placeholder);

const LazyImage = ({
    src = placeholder,
    alt,
    classNameWrapper,
    isLocal = false,
    useImg = false,
    ...rest
}) => {
    const transformToWebp = () => {
        if (isLocal || !checkWebpSupport()) {
            return src;
        }
        let regex = new RegExp(/\.(jpeg|png|jpg)/g);
        return src.replace(regex, '.webp');
    };

    if (!src || src === placeholder) {
        return (
            <div className={`lazyload-wrapper placeholder-bg ${classNameWrapper || ''}`}>
                <img src={placeholder} alt="Placeholder" loading="lazy" {...rest} />
            </div>
        );
    }

    if (useImg) {
        return <img {...rest} src={transformToWebp()} alt={alt} onError={handleError} />;
    }
    return (
        <LazyLoad className={classNameWrapper} offset={300}>
            <img {...rest} src={transformToWebp()} alt={alt} onError={handleError} />
        </LazyLoad>
    );
};

LazyImage.propTypes = {
    alt: PropTypes.string,
    classNameWrapper: PropTypes.string,
    isLocal: PropTypes.bool,
    useImg: PropTypes.bool,
    src: PropTypes.string
};

export default LazyImage;
