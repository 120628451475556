import './stick-network.scss';

import React, { useEffect, useState } from 'react';

import FbIcon from '../../assets/images/icons/fb-stick.svg';
import instagramIcon from '../../assets/images/icons/ig-stick.svg';
import twitterIcon from '../../assets/images/icons/tw-stick.svg';
import { GET_SOCIAL } from '../../graphQL/queries/configQuery';
import useFetch from '../../hooks/useFetch';
import LazyImage from '../lazyImage/lazyImage.component';

const SHOW_DISTANCE = 250;
const SHOW_CLASS = ' show';
const ICONS = {
    facebook: FbIcon,
    twitter: twitterIcon,
    instagram: instagramIcon
};

function StickNetwork() {
    const { isLoading, error, data } = useFetch('social-configs', GET_SOCIAL);
    const [scrollY, setScrollY] = useState();

    const socialsData = data?.social?.settings?.[0];

    useEffect(() => {
        const handleScroll = () => {
            const scroll = window.scrollY;
            if (scroll >= SHOW_DISTANCE && scrollY !== SHOW_CLASS) {
                setScrollY(SHOW_CLASS);
            } else if (scroll < SHOW_DISTANCE && scrollY !== '') {
                setScrollY('');
            }
        };

        window.addEventListener('scroll', handleScroll, { passive: true });

        // Cleanup the event
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    if (isLoading || error || !socialsData) {
        return null;
    }

    return (
        <div className={`stick-network${scrollY || ''}`}>
            {Object.entries(socialsData).map(([key, value]) => {
                if (!value || !ICONS[key]) return null;
                return (
                    <div className="d-flex justify-content-center stick-item-wrapper" key={key}>
                        <div className="d-flex stick-item">
                            <a
                                href={value?.url?.path}
                                target="_blank"
                                rel="noreferrer"
                                aria-label={'Social Media Link'}
                            >
                                <LazyImage
                                    src={ICONS[key]}
                                    alt={value?.title}
                                    useImg
                                    isLocal
                                    height={25}
                                    width={25}
                                />
                            </a>
                        </div>
                    </div>
                );
            })}
        </div>
    );
}

export default React.memo(StickNetwork);
