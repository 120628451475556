// Styles
import './top.tabs.styles.scss';

import * as _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import arrowIcon from '../../../../assets/images/icons/arrow.svg';
import ButtonAction from '../../../_shared/buttons/buttonsAction.component';
import LazyImage from '../../../lazyImage/lazyImage.component';

const TopTabs = ({ categories, action, currentFilter, noCollapse }) => {
    const [open, setOpen] = useState(screen.width > 576);
    const [currentCategories, setCurrentCategories] = useState([]);

    useEffect(() => {
        if (currentCategories.length <= 0 && categories.length > 0) {
            // Clone categories because it is an object not extensible.
            const categoriesClone = _.cloneDeep(categories);

            // Add All Category
            categoriesClone.unshift({ id: 'all', name: 'Todos' });
            setCurrentCategories(categoriesClone);
        }
    }, [currentCategories, categories]);

    return (
        <div className={`event-tabs${noCollapse ? ' border-bottom-0' : ''}`}>
            {screen.width <= 576 && !noCollapse && (
                <div className="d-flex">
                    <ButtonAction
                        className="link-primary d-flex align-items-center text-uppercase categories-toggler"
                        onClick={() => setOpen(!open)}
                        type="button"
                    >
                        <span>Categorías</span>
                        <LazyImage src={arrowIcon} alt="Collapse categories" />
                    </ButtonAction>
                </div>
            )}
            <React.Fragment>
                {(open || noCollapse) && (
                    <ul className={noCollapse ? 'flex-row overflow-scroll-x' : ''}>
                        {currentCategories &&
                            currentCategories.map((item) => {
                                return (
                                    <li key={item.id}>
                                        <ButtonAction
                                            className={`button-filter ${
                                                currentFilter === item.id ? 'button--active' : ''
                                            }`}
                                            onClick={() => action(item.id)}
                                        >
                                            {item.name.length < 30
                                                ? item.name
                                                : item.name.substring(0, 30) + '...'}
                                        </ButtonAction>
                                    </li>
                                );
                            })}
                    </ul>
                )}
            </React.Fragment>
        </div>
    );
};

TopTabs.propTypes = {
    categories: PropTypes.array,
    currentFilter: PropTypes.any,
    action: PropTypes.func,
    noCollapse: PropTypes.bool
};

export default React.memo(TopTabs);
