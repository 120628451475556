import './playButton.styles.scss';

import PropTypes from 'prop-types';
import React from 'react';

import IconPlay from '../../../assets/images/play_arrow.svg';

const PlayButton = ({ classes, callback }) => {
    return (
        <button type="button" className={`button-play ${classes}`} onClick={callback}>
            <img src={IconPlay} alt="Play" className={classes} />
        </button>
    );
};

PlayButton.propTypes = {
    classes: PropTypes.any,
    callback: PropTypes.any
};

export default PlayButton;
