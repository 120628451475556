import './submenu.styles.scss';

import PropTypes from 'prop-types';
import React from 'react';

const SubMenu = ({ links }) => {
    return (
        <div className="submenu">
            <div className="d-flex flex-row flex-nowrap">
                <ul className="link-list">
                    {links.map((link) => (
                        <li key={link.title} className="link-list__link">
                            <a href={link.url} target="_blank" rel="noreferrer">
                                {link.title}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

SubMenu.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    links: PropTypes.array.isRequired
};

export default React.memo(SubMenu);
