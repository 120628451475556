import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { calendarReducer } from './calendar/calendar.reducers';

// Reducers
const persistConfig = {
    key: 'root',
    storage
};

const rootReducer = combineReducers({
    calendar: calendarReducer
});

export default persistReducer(persistConfig, rootReducer);
