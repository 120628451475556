import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import useGetAllEvents from '../hooks/useGetAllEvents';
import { useQueryParams } from '../hooks/useQueryParams';

const EventsContext = React.createContext({});

export const EventsContextProvider = ({ children }) => {
    const queryParams = useQueryParams();

    const { data, count, loading } = useGetAllEvents();
    const [paramsFilters, setParamsFilters] = useState([]);

    useEffect(() => {
        const updateParams = (entries) => {
            let filters = {};
            for (let [key, val] of entries) {
                let value = val;
                if (key === 'dateRange') {
                    value = val.split(',');
                }
                filters[key] = value;
            }
            setParamsFilters(filters);
        };
        updateParams(queryParams.entries());
    }, [queryParams]);

    return (
        <EventsContext.Provider value={{ data, count, loading, paramsFilters }}>
            {children}
        </EventsContext.Provider>
    );
};

EventsContextProvider.propTypes = {
    children: PropTypes.any
};

export default EventsContext;
