import 'moment/locale/es';

import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import { useLocation } from 'react-router';

import defaultImage from '../../assets/images/bicentenario.webp';
// Components
import Footer from '../../components/footer/footer.component';
import Menu from '../../components/menu/menu.component';
import StickNetwork from '../../components/stickNetwork/stick-network';
import { GET_SOCIAL } from '../../graphQL/queries/configQuery';
import useFetch from '../../hooks/useFetch';

moment.locale('es');

const InternalLayout = ({ children, title = '', description }) => {
    const { isLoading, error, data } = useFetch('social-configs', GET_SOCIAL);
    const location = window.location.href;
    const siteTitle = 'Bicentenario de la Independencia de Costa Rica';
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, [pathname]);

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    {title}
                    {title !== '' ? ' | ' : ''}
                    {siteTitle}
                </title>
                <meta name="description" content={description} />
                <meta property="og:title" content={`${title} | ${siteTitle}`} />
                <meta property="og:description" content={description} />
                <meta property="og:image" content={defaultImage} />
                <meta property="og:type" content="website" />
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:title" content={`${title} | ${siteTitle}`} />
                <meta name="twitter:description" content={siteTitle} />
                <meta name="twitter:image" content={defaultImage} />
                <meta name="twitter:url" content={location} />
                <meta property="og:url" content={location} />
                <meta name="robots" content="all" />
                <link rel="canonical" href={location} />
            </Helmet>

            {!isLoading && !error && (
                <>
                    <Menu
                        srcBanner={data.social.settings[0].logoNavbar.entity.image.url}
                        srcHeroBanner={data.social.settings[0].logoHeroBanner.entity.image.url}
                    />
                    <div style={{ minHeight: '100vh' }}>{children}</div>
                    <Footer social={data.social} />
                </>
            )}

            <StickNetwork />
        </div>
    );
};

InternalLayout.propTypes = {
    children: PropTypes.any.isRequired,
    title: PropTypes.string,
    description: PropTypes.string
};

export default InternalLayout;
