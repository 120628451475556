import './videoGrid.styles.scss';

import PropTypes from 'prop-types';
import React from 'react';

import { GET_GRID_VIDEOS } from '../../graphQL/queries/videoQueries';
import useFetch from '../../hooks/useFetch';
import ButtonAction from '../_shared/buttons/buttonsAction.component';
import { Text } from '../_shared/loadingMessage/loadingMessage.component';
import Paginator from '../paginator/paginator.component';
import VideoCard from '../videocarousel/children/videoCard.component';

const VideoGrid = ({ gridTitle, handleClick, categoryId, limit, isPreview }) => {
    const [page, setPage] = React.useState(1);
    const queryId = [`grid-videos`, `${categoryId}-${page}`];

    const { data, isLoading } = useFetch(queryId, GET_GRID_VIDEOS, {
        id: String(categoryId),
        limit: limit,
        offset: (page - 1) * limit
    });

    const handleFilter = React.useCallback(
        () => handleClick(categoryId),
        [categoryId, handleClick]
    );

    const handlePagination = React.useCallback((pageNum) => {
        setPage(pageNum);
    }, []);

    return (
        <section className="video-grid container">
            <div className="grid-title-container d-flex justify-content-between align-items-center">
                <h3 className="grid-title w-100 pr-2">{gridTitle}</h3>
                {isPreview && (
                    <div className="d-flex justify-content-end align-items-end pt-3 pt-lg-5">
                        <ButtonAction onClick={handleFilter} className="link-primary">
                            Ver más
                        </ButtonAction>
                    </div>
                )}
            </div>
            {isLoading || !data?.results?.videos ? (
                <Text style={{ minHeight: '310px' }} />
            ) : (
                <React.Fragment>
                    <div className="row">
                        <div className="content-grid">
                            {data?.results?.videos?.map((vid, index) => (
                                <VideoCard
                                    key={index}
                                    cover={vid.cover.entity.media.fluidImg.url}
                                    title={vid.title}
                                    description={vid.description}
                                    redirectUrl={`/video/${vid.id}`}
                                />
                            ))}
                        </div>
                    </div>
                    {data?.results?.total > limit && isPreview === false && (
                        <div className="mt-5 pt-3">
                            <Paginator
                                itemsLength={data.results.total}
                                paginate={handlePagination}
                                itemsPerPage={limit}
                                page={page}
                            />
                        </div>
                    )}
                </React.Fragment>
            )}
        </section>
    );
};

VideoGrid.propTypes = {
    gridTitle: PropTypes.string.isRequired,
    handleClick: PropTypes.func,
    categoryId: PropTypes.number,
    limit: PropTypes.number,
    isPreview: PropTypes.bool
};

VideoGrid.defaultProps = {
    limit: 8
};

export default VideoGrid;
